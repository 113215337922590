import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  status: "loading",
};

export const customReducer = createReducer(initialState, {
  setPage: (state, action) => {
    if (action.payload.setAlerts) {
      state.status = "alerts";
    } else {
      state.status = "intro";
    }
  },
});
