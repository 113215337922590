import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Intro from "./components/intro";
import Alerts from "./components/alerts";
import Loading from "./components/loading";
import mapAlerts from "./utils/mapAlerts";

function App({ address }) {
  const [alerts, setAlerts] = useState(null);
  const { status } = useSelector((state) => state.custom);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.post("/api/sendAlerts.js", { address }).then((value) => {
      dispatch({
        type: "setPage",
        payload: { setAlerts: value.data.sendAlerts },
      });
      if (value.data.sendAlerts) {
        axios.post("/api/getAlerts.js", { address }).then((value) => {
          mapAlerts(value.data, setAlerts, address);
        });
      }
    });
  }, []);

  return (
    <div className="main">
      {(() => {
        if (status === "loading") {
          return <Loading />;
        } else if (status === "intro") {
          return <Intro />;
        } else {
          return <Alerts {...alerts} {...{ setAlerts }} />;
        }
      })()}
    </div>
  );
}

export default App;
