//import { fontSize } from '@mui/system';
import React from 'react';
import './rewardsSnapshot.css';
import { Container, Row, Col } from 'react-grid-system';
import goldClass from '../goldClass.png';
import silverClass from '../silverClass.png';
import bronzeClass from '../bronzeClass.png';



//import { Line, Circle } from 'rc-progress';
//import './sortableTable.css';
//import randomColor from 'randomcolor';


const ReadMe = (props) => {
  const userClass = props.userClass;
  const userClassImage = props.userClassImage;
  const usdPrice = props.usdPrice;
  const memberShip = props.memberShip;
  //const userSummary=props.userSummary;
  const subscribedValue = props.subscribedValue;
  const stakedToRadixStaker = props.stakedToRadixStaker;
  const positionTotalStaked = props.positionTotalStaked;
  const stakedToRadixStakerPercentage = props.stakedToRadixStakerPercentage;
  // console.log(subscribedValue)
  // console.log(props)
  // console.log(usdPrice)



  let userSummary1 = "";
  let userSummary2 = "";

  let notGoldMsg = "";
  if (userClass !== "Gold") {
    if (userClass === "Silver") {
      notGoldMsg = `$${25 - subscribedValue} (${Math.ceil((25 - subscribedValue) / usdPrice)} XRD) is required to upgrade to Gold membership`;
    }
    else if (userClass === "Bronze") {
      notGoldMsg = `$${50 - subscribedValue} (${Math.ceil((50 - subscribedValue) / usdPrice)} XRD) is required to upgrade to Gold membership`;
    }
  }


  if (userClass === 'Gold') {
    console.log("User summary: Gold");
    userSummary1 = ((stakedToRadixStaker - (positionTotalStaked * .1)).toFixed(0) + " XRD can be unstaked before losing Gold status.");
    userSummary2 = ((stakedToRadixStaker - (positionTotalStaked * .05)).toFixed(0) + " XRD can be unstaked before losing Silver status");

  }
  else if (userClass === 'Silver') {
    userSummary1 = (stakedToRadixStaker - (positionTotalStaked * .05)).toFixed(0) + " XRD can be unstaked before losing Silver status";
    userSummary2 = ((positionTotalStaked * .1) - stakedToRadixStaker).toFixed(0) * 1.03 + " XRD stake required to attain Gold status";
  }
  else {
    userSummary1 = ((positionTotalStaked * .05) - stakedToRadixStaker).toFixed(0) * 1.03 + " XRD stake required to attain Silver status";
    userSummary2 = ((positionTotalStaked * .1) - stakedToRadixStaker).toFixed(0) * 1.03 + " XRD stake required to attain Gold status";
  }

  return (

    <Container>
      <Row>
        <Col md={12} style={{ backgroundColor: 'white', textAlign: 'center' }}>

          <div style={{ display: 'table', height: '30px', maxWidth: '100%', whiteSpace: 'initial', overflow: 'hidden', margin: 'auto', backgroundColor: 'white' }}>
            <p>RadixStaker is proud to support the Radix community by providing Bronze class portfolio tracking to all community members staking Radix tokens.
              <br></br>To thank our valued customers for staking with us we provide Silver and Gold class features based on the value staked with radixstaker.com
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ backgroundColor: 'white', textAlign: 'center' }}>
          <div style={{ display: 'table', height: '90px', maxWidth: '100%', whiteSpace: 'initial', overflow: 'hidden', margin: 'auto', backgroundColor: 'white' }}>
            <br></br><img style={{ verticalAlign: 'top' }} src={bronzeClass} className="coinImageSmall" alt="" />
            Bronze class: Included features available to community members with no minimum requirement for delegation to Radix Staker:
            <br></br>  🧛‍ Validators - Stake distribution and key metrics for validators (eg validator fee, rewards earned, current APY).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br></br>  🧛‍ Unstaking Status - Progress of in-flight unstake transactions including epochs/days until completes.&nbsp;
            <br></br>
            <br></br><img style={{ verticalAlign: 'top' }} src={silverClass} className="coinImageSmall" alt="" />
            Silver class: Included features available to all community members with 5-10% of their staked tokens delegated to Radix Staker:
            <br></br>  🧛‍ Rewards Snapshot - Per validator/per epoch rewards for current date. Addtional dates accessible via datepicker.&nbsp;
            <br></br>  🧛‍ Alerts - Alerting of users to any Validator state changes (eg uptime, unregistration, fees). &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br></br>
            <br></br><img style={{ verticalAlign: 'top' }} src={goldClass} className="coinImageSmall" alt="" />
            Gold class: Included features available to our biggest supporters with &gt;10% of their staked tokens delegated to Radix Staker:&nbsp;&nbsp;&nbsp;
            <br></br>  🧛‍ Income Report - Per validator/per day, date range rewards report (download as csv) for income tax calculations.&nbsp;
            <br></br>  🧛‍ CGT report - Token aquistion/disposal report with price feeds to support CGT calculations. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


            <p></p>
            <p>Your current user class is {userClass} <br></br>  <img src={userClassImage} className="coinImage" alt="" /> <br></br>with
              &nbsp;{stakedToRadixStaker.toFixed(0)} XRD ({stakedToRadixStakerPercentage.toFixed(2)} % of total) staked to Radix Staker<br></br>
            </p>
            {userClass !== 'Gold' && <p>{notGoldMsg}</p>}
            <p></p>
            {memberShip && <p>An Active Subscription is providing by Gold memberShip</p>}
            {(!memberShip && userClass === 'Gold') && <p>{userSummary1}<br></br>{userSummary2}</p>}


          </div>
        </Col>
      </Row>
    </Container>




  );
}

export default ReadMe;
