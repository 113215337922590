import React from 'react';
import './sortableTable.css';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const SortableTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const currencySymbol=props.currencySymbol;
  const tokenPrice=props.tPrice;
  const totalDelegatedStakeNextEpoch=props.totalDelegatedStakeNextEpoch;
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };


  return (
    <table style={{width:'100%'}}> 
      <thead style={{padding:'0em'}}>
        <tr>
          <th >
            <button 
              type="button"
              onClick={() => requestSort('label')}
              className={getClassNamesFor('label')}
            >
              
            </button>
          </th>
          <th >
            <button 
              type="button" 
              onClick={() => requestSort('validatorName')}
              className={getClassNamesFor('validatorName')}
            >
            Validator Name
            </button>
          </th>
          <th >
            <button 
              type="button" 
              onClick={() => requestSort('stakePercentage')}
              className={getClassNamesFor('stakePercentage')}
            >
            Stake %
            </button>
          </th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('validatorOwnStake')}
              className={getClassNamesFor('validatorOwnStake')}
            >
              Owner Stake
            </button>
          </th>
          <th>
            <button
              type="button" 
              onClick={() => requestSort('validatorFee')}
              className={getClassNamesFor('validatorFee')}
            >
              Fee%
            </button>
          </th>
          <th>
            <button 
              type="button" 
              onClick={() => requestSort('validatorUptime')}
              className={getClassNamesFor('validatorUptime')}
            >
              Uptime%
            </button>
          </th>

          <th >
            <button
              type="button" 
              onClick={() => requestSort('validatorPosition')}
              className={getClassNamesFor('validatorPosition')}
            >
              Top 100
            </button>
          </th>
          <th>
            <button
              type="button" 
              onClick={() => requestSort('stakePositionAmount')}
              className={getClassNamesFor('stakePositionAmount')}
            >
              Total Staked
            </button>
          </th>

          <th>
            <button
              type="button" 
              onClick={() => requestSort('principalStake')}
              className={getClassNamesFor('principalStake')}
            >
              Principal Stake
            </button>
          </th>

          <th>
            <button
              type="button" 
              onClick={() => requestSort('rewards')}
              className={getClassNamesFor('rewards')}
            >
              Rewards
            </button>
          </th>

          <th>
          <button
              type="button" 
              onClick={() => requestSort('APY')}
              className={getClassNamesFor('APY')}
            >
            APY%
            </button>
         
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((validator,index) => {
     //       console.log(validator.validator);

          var urlRoot = "https:\/\/explorer.radixdlt.com\/#\/validators\/";
            return(
              <tr style={{height:'10px'}} key={index}>
              <td style={{backgroundColor:[validator.color]}}></td>
              <td style={{textAlign: 'center'}}><a href ={urlRoot+validator.validator} target="_blank">{validator.validatorName.substring(0,24)}</a></td>
              <td style={{textAlign: 'center'}}>{(validator.stakePercentage).toFixed(2)}</td>
              <td style={{textAlign: 'center'}}>{(validator.validatorOwnStake/1000000000000000000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
              <td style={{textAlign: 'center'}}>{validator.validatorFee}</td>
              <td style={{textAlign: 'center'}}>{validator.validatorUptime}</td>
            {/*  <td style={{textAlign: 'center'}}>{validator.inNextEpoch ? 'Yes':'No'} </td>*/}
            
              <td style={{textAlign: 'center'}}>{validator.validatorPosition}</td>
              <td style={{textAlign: 'center'}}><p></p>{(validator.stakePositionAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<p style={{fontSize: '66%'}}>{currencySymbol}{(validator.stakePositionAmount*tokenPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></td>
              <td style={{textAlign: 'center'}}><p></p>{(validator.totalStaked).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<p style={{fontSize: '66%'}}>{currencySymbol}{(validator.totalStaked*tokenPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></td> 
              <td style={{textAlign: 'center'}}><p></p>{(validator.rewards).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<p style={{fontSize: '66%'}}>{currencySymbol}{(validator.rewards*tokenPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></td> 
              <td style={{textAlign: 'center'}}>{validator.APY}</td> 
              </tr>
           
        )})}
      </tbody>
    </table>
   
  );
};


export default SortableTable;