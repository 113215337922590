import axios from 'axios';
import React from 'react';
import SnapShotLoading from './rewardsSnapShotLoading';
//import './rewardsSnapshot.css';
import './sortableTable.css';
import gatewayApi from '../gateway-api';

let GlobalVList;

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const RewardsSnapshot = (props) => {
  const [validatorlist, setvalidatorlist] = React.useState();
  // console.log(props)

  //fetching validators..
  React.useEffect(() => {
    // getValidatorsList();
    if (!GlobalVList) {
      gatewayApi
        .post('/validators', {
          network_identifier: {
            network: 'mainnet',
          },
          validator_identifier: {
            address: '',
          },
        })
        .then((res) => {
          const jdata = {};
          res.data.validators.forEach((validator) => {
            jdata[validator?.validator_identifier?.address] =
              validator?.properties?.name;
          });
          setvalidatorlist(jdata);
          GlobalVList = jdata;
          console.log('inside useEffect');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const { items, requestSort, sortConfig } = useSortableData(
    props.snapshotRewards
  );
  const currentCurrency = props.cCurrency;
  const currencyPrice =
    props?.PriceList?.market_data?.current_price[currentCurrency];
  const snapshotPicker = props.snapshotPicker;
  const userClass = props.userClass;
  const currencySymbol = props.currencySymbol;

  if (!props.snapButt) {
    return <SnapShotLoading />;
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  //console.log(snapshotPicker);
  //console.log(userClass);
  if (snapshotPicker === false && props.userClass === 'Bronze') {
    return '';
  } else {
    return (
      <>
        {props.snapFirstSearch ? (
          <table style={{ width: '100%' }}>
            <thead style={{ padding: '0em' }}>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <button
                    type='button'
                    onClick={() => requestSort('date')}
                    className={getClassNamesFor('date')}
                  >
                    Date
                  </button>
                </th>
                <th>
                  <button type='button'>Time</button>
                </th>
                <th>
                  <button
                    type='button'
                    onClick={() => requestSort('epoch')}
                    className={getClassNamesFor('epoch')}
                  >
                    Epoch
                  </button>
                </th>
                <th>
                  <button
                    type='button'
                    onClick={() => requestSort('validatorName')}
                    className={getClassNamesFor('validatorName')}
                  >
                    Validator Name
                  </button>
                </th>
                <th>
                  <button
                    type='button'
                    onClick={() => requestSort('rewards')}
                    className={getClassNamesFor('rewards')}
                  >
                    Amount (XRD)
                  </button>
                </th>

                <th>
                  <button type='button'>Price ({currencySymbol})</button>
                </th>
                <th>
                  <button type='button'>Value ({currencySymbol})</button>
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map((item, index) => {
                var rewardDate = new Date(item.time);
                var Vaddress = item.validator;
                // console.log(item.time);
                var date = rewardDate.toLocaleDateString();
                var time = rewardDate.toLocaleTimeString();

                // console.log(priceList);

                return (
                  <tr style={{ height: '30px' }} key={index}>
                    <td style={{ textAlign: 'center' }}>{date}</td>
                    <td style={{ textAlign: 'center' }}>{time}</td>
                    <td style={{ textAlign: 'center' }}>{item.epoch}</td>
                    {/* <td style={{ textAlign: 'center' }}>{validatorlist ? validatorlist[Vaddress] : 'Loading...'}</td> */}
                    <td style={{ textAlign: 'center' }}>
                      {GlobalVList[Vaddress]}
                    </td>
                    <td style={{ textAlign: 'center' }}>{item.reward}</td>
                    <td style={{ textAlign: 'center' }}>
                      {currencyPrice.toFixed(4)}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {(item.reward * currencyPrice).toFixed(4)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default RewardsSnapshot;
