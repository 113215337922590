import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ReactGA from 'react-ga';

let urlPrefix = window.location.href;
let trackingID = '';
if (urlPrefix.substr(7, 3) == 'dev') {
  trackingID = 'UA-209377775-1';
  //    console.log(trackingID);
} else {
  trackingID = 'UA-205247964-1';
  //    console.log(trackingID);
}
//

ReactGA.initialize(trackingID); // add your tracking id here 283295911.
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
