import { useRef } from "react";
import "./style.css";
import submitAlerts from "../../utils/submitAlerts";
import Loading from "../loading";

function DisplayAlerts({ alerts, address, setAlerts }) {
  const alertsContainer = useRef(null);

  return (
    <div className="alerts-section">
      <div className="container alertsHeader" style={{ padding: "0 1rem" }}>
        <p>
          Current alerts for searched address:{" "}
        </p>
        <p className="address">{address}</p>
      </div>
      <div ref={alertsContainer} className="container alertsContainer">
        {alerts}
      </div>
      <div className="buttonsContainer">
        <button
          className="submit"
          onClick={() => submitAlerts(alertsContainer, address, setAlerts)}
        >
          Acknowledge Alerts
        </button>
      </div>
    </div>
  );
}

function NoAlerts({ message, address }) {
  return (
    <div className="noAlerts">
      <p style={{height:'10px', verticalAlign: 'center' ,textAlign: 'center',  alignContent: 'center', fontWeight: 'bold', color:'black'}}>{message}</p>
      <p style={{height:'10px', verticalAlign: 'center' ,textAlign: 'center',  alignContent: 'center', fontWeight: 'bold', color:'black'}}>{address}</p>
    </div>
  );
}

function Alerts({ alerts, address, done, setAlerts }) {
  if (done) {
    if (Array.isArray(alerts) && alerts.length !== 0) {
      return <DisplayAlerts {...{ alerts, address, setAlerts }} />;
    } else {
      return (
        <NoAlerts
          {...{
            message: "Currently there are no alerts for this address",
            address,
          }}
        />
      );
    }
  } else {
    return <Loading />;
  }
}

export default Alerts;
