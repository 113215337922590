import React, { useEffect, useState, useRef, useMemo } from 'react';
import './rewardsSnapshot.css';
import './toolTip.css';
import csvDownload from '../csvDownload.png';
import { Client, getContainerClient } from '../utils/storageblob';
import DatePicker from 'react-datepicker';
import { Col } from 'react-grid-system';
import searchButton from '../search.png';
import axios from 'axios';
import gatewayApi from '../gateway-api';

const RewardsTable = (props) => {
  // console.log(props);
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState({ value: undefined, name: '' });
  const anchortag = useRef(null);
  const [searchDate, setSearchDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  let dateFormat = 'd/M/yyyy';
  const [showMessage, setShowMessage] = useState(false);
  const address = useMemo(() => props.address, []);
  const [deleteFilesNames, setDeleteFilesNames] = useState(new Set([]));
  const [format, setFormat] = useState([]);

  const getClientData = () => {
    setLoading(true);
    getContainerClient(address).then((value) => {
      const instance = new Client(value);
      instance.listFiles().then(() => {
        setClient(instance);
        setLoading(false);
        setDeleteFilesNames(new Set([]));
      });
    });
    // console.log(client);
  };

  useEffect(() => {
    getClientData();
  }, []);

  useEffect(() => {
    if (anchortag.current) anchortag.current.click();
    return () => {
      URL.revokeObjectURL(url.value);
    };
  }, [url]);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 20000);
  }, [showMessage]);

  const currencySymbol = props.currencySymbol;

  return (
    <>
      <Col md={12} style={{ textAlign: 'center' }}>
        <DatePicker
          wrapperClassName='datePicker'
          selected={searchDate.startDate}
          onChange={(date) => setSearchDate({ ...searchDate, startDate: date })}
          dateFormat={dateFormat}
        />
        <DatePicker
          wrapperClassName='datePicker'
          selected={searchDate.endDate}
          onChange={(date) => setSearchDate({ ...searchDate, endDate: date })}
          dateFormat={dateFormat}
        />
        <button
          type='submit'
          className='search-button-incomeRewards'
          onClick={() => {
            console.log(searchDate);
            gatewayApi
              .post('/api/get-rewards', {
                startDate: searchDate.startDate,
                endDate: searchDate.endDate,
                address,
              })
              .then(() => {
                setShowMessage(true);
              });
          }}
        >
          <img src={searchButton} alt='' />
        </button>
      </Col>

      {showMessage && (
        <Col md={12} style={{ textAlign: 'center' }}>
          <p>
            Querying the radix ledger for reward transactions over selected date
            range.<br></br>
            Time taken depends on the search range (1 day approx 30 secs, 1
            month approx 15 mins, 1 year approx 3 hrs).<br></br>
            Your search results will be available below when the search is
            complete. <br></br>
            Please check back again later.
          </p>
        </Col>
      )}
      {!loading && (client === undefined || client?.files?.length === 0) && (
        <>
          <table style={{ width: '100%', marginTop: '1rem' }}>
            <thead style={{ padding: '0em' }}>
              <tr></tr>
              <tr></tr>

              <tr
                style={{
                  height: '10px',
                  verticalAlign: 'center',
                  textAlign: 'center',
                  alignContent: 'center',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                there are no saved searches{' '}
              </tr>
              <tr></tr>
              <tr></tr>
            </thead>
          </table>
        </>
      )}

      {!loading && client !== undefined && client?.files?.length !== 0 && (
        <>
          <table style={{ width: '100%' }}>
            <thead style={{ padding: '2em' }}>
              <tr>
                <th style={{ textAlign: 'center' }}>Search Interval</th>
                <th style={{ textAlign: 'center' }}>Download Format</th>
                <th>
                  <button type='button'>Download</button>
                </th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {client.files.map((file, index) => {
                return (
                  <tr style={{ height: '30px' }} key={index}>
                    <td style={{ textAlign: 'center' }}>
                      {new Date(
                        parseInt(file.value.name.split('_')[1])
                      ).toDateString()}
                      -
                      {new Date(
                        parseInt(file.value.name.split('_')[2].split('.')[0])
                      ).toDateString()}
                    </td>

                    <td>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            flex: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <label for='raw' style={{ margin: 0 }}>
                            Raw
                          </label>
                          <input
                            type='radio'
                            id='Rraw'
                            name={'format' + index}
                            value='raw'
                            style={{ margin: 0 }}
                            onChange={(e) => {
                              setFormat((val) => {
                                const old = [...val];
                                old[index] = e.target.value;
                                return old;
                              });
                            }}
                            defaultChecked={true}
                          />
                        </div>
                        <div
                          style={{
                            flex: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <label for='koinly' style={{ margin: 0 }}>
                            Koinly
                          </label>
                          <input
                            type='radio'
                            id='koinly'
                            name={'format' + index}
                            value='koinly'
                            style={{ margin: 0 }}
                            onChange={(e) => {
                              setFormat((val) => {
                                const old = [...val];
                                old[index] = e.target.value;
                                return old;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </td>

                    <td
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        if (format[index] === 'koinly') {
                          client
                            .getKoinlyFile(file.value.name)
                            .then((value) => {
                              setUrl({
                                value,
                                name: 'radix-rewards-koinly.csv',
                              });
                            });
                        } else {
                          client.getRawFile(file.value.name).then((value) => {
                            setUrl({
                              value,
                              name: 'radix-rewards-raw.csv',
                            });
                          });
                        }
                      }}
                    >
                      <img
                        style={{ width: '30px', cursor: 'pointer' }}
                        src={csvDownload}
                        alt=''
                      />
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          type='checkbox'
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (!deleteFilesNames.has(file.value.name)) {
                                deleteFilesNames.add(file.value.name);
                                setDeleteFilesNames(new Set(deleteFilesNames));
                              }
                            } else {
                              if (deleteFilesNames.has(file.value.name)) {
                                deleteFilesNames.delete(file.value.name);
                                setDeleteFilesNames(new Set(deleteFilesNames));
                              }
                            }
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='buttonsContainer'>
            <button
              onClick={() => {
                // console.log("delete cliked")
                axios.post('/api/deleteFiles', {
                  fileNames: [...deleteFilesNames],
                  containerName: client.client.containerName,
                });
                getClientData();
              }}
            >
              Delete files
            </button>
          </div>
        </>
      )}
      <a
        href={url.value}
        ref={anchortag}
        download={`${url.name}`}
        style={{ display: 'none' }}
      >
        download
      </a>
      {loading && (
        <div className='loading' style={{ textAlign: 'center' }}>
          <p>Loading previous searches...</p>
        </div>
      )}
    </>
  );
};

export default RewardsTable;
