import React from 'react';
//import { Line, Circle } from 'rc-progress';
//import './sortableTable.css';
//import randomColor from 'randomcolor';

function manageAddress() {
  return (
    <div className="" style={{textAlign:'center'}}>
     <p>Under development</p>
     </div> 
  );
}

export default manageAddress;
