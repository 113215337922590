import React from "react";
import Alerts from "./alerts/App";
import { Provider } from "react-redux";
import store from "./alerts/store";
//import { Line, Circle } from 'rc-progress';
//import './sortableTable.css';
//import randomColor from 'randomcolor';

function alerts({ address }) {
  return (
    <Provider store={store}>
      <Alerts {...{ address }} />
    </Provider>
  );
}

export default alerts;
