function mapAlerts(alerts, setAlerts, address) {
  if (alerts && Array.isArray(alerts.alerts) && alerts.alerts.length !== 0) {
    const alertsHTML = [];
    for (var index in alerts.alerts) {
      const temp = [];
      let tempKey = 0;
      if (alerts.alerts[index] === null) {
        continue;
      }
      for (let key in alerts.alerts[index]) {
        tempKey++;
        if (key === "validator") {
          continue;
        }
        temp.push(
          <li key={tempKey}>
            {key} : {alerts.alerts[index][key]}
          </li>
        );
      }
      temp.push(
        <li
          className="validator"
          key={tempKey++}
          style={{
            fontSize: "1.5rem",
            wordWrap: "anywhere",
          }}
        >
          validator : {alerts.alerts[index]["validator"]}
        </li>
      );
      alertsHTML.push(
        <div key={index} className="alertsBox">
          <ul className="alertsList">{temp}</ul>
          <input type="checkbox" />
        </div>
      );
    }
    const output = {
      alerts: alertsHTML,
      address: address,
      done: true,
    };
    setAlerts(output);
  } else {
    const output = { address: address, done: true };
    setAlerts(output);
  }
}

export default mapAlerts;
