import React from 'react'

const SnapShotLoading = () => {
    return (
        <div style={{ width: '100%', marginTop: "5vh" }} >
            <div style={{ color: "black", textAlign: "center", fontSize: "3rem" }} >
                Retrieving per epoch rewards from the ledger. Please wait 30 secs. 
            </div>
        </div>
    )
}

export default SnapShotLoading