import { gql } from '@apollo/client'


export const GET_TOKEN_DATA = gql`
    query getTokens {
    tokens_ranked_mc( order_by: {rank: asc}) {
    name
    market_cap_usd
    rank
    rri
    icons {
      small
      medium
      large
    }
    price {
      usd
      usd_7d
      usd_24h
    }
    volume {
      usd_7d
      usd_24h
    }
      volume {
        usd_24h
        usd_7d
      }
    }
  }
    `
