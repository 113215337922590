import React from 'react'

const NoSnapshotAvi = () => {
    return (
        <div style={{ width: '100%', marginTop: "5vh" }} >
            <div style={{ textAlign: "center", fontSize: "3rem" }} >
                No Data Available
            </div>
        </div>
    )
}

export default NoSnapshotAvi