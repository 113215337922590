import axios from "axios";
import mapAlerts from "./mapAlerts";

function submitAlerts(alertsContainer, address, setAlerts) {
  const validators = [];
  alertsContainer.current.childNodes.forEach((value, index) => {
    if (value.lastChild.checked) {
      validators.push({
        validator: value.firstChild
          .getElementsByClassName("validator")[0]
          .textContent.split(" ")[2],
        createdAt: value.firstChild
          .getElementsByClassName("validator")[0]
          .previousElementSibling.textContent.split(" ")[2],
      });
    }
  });

  axios
    .post("/api/rmAlerts", { address: address, validators: validators })
    .then((value) => {
      if (value.data.updated) {
        alert("Alerts acknowledged successfully");
        if (validators.length !== alertsContainer.current.childNodes.length) {
          axios
            .post("/api/getAlerts", { address: address })
            .then((value) =>
              mapAlerts(value.data, setAlerts, address, value.status)
            );
        } else if (
          validators.length === alertsContainer.current.childNodes.length
        ) {
          const output = {
            alerts: [],
            address: address,
            valid: true,
            done: true,
          };
          setAlerts(output);
        }
      }
    });
}

export default submitAlerts;
