import React from 'react';
import './sortableTable.css';
import { Container, Row, Col } from 'react-grid-system';

import { CSVLink } from 'react-csv';
import csvDownload from '../csvDownload.png';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const CGTTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(
    props.cgtTransactions
  );
  const [type, setType] = React.useState('raw');

  const currencySymbol = props.currencySymbol;
  const histPrices = props.hPrices;

  const csvData = items.map((cgtTransactions) => {
    let price = 'price unavailable';
    const dateArray = cgtTransactions.sentAt.split(`T`)[0].split(`-`);
    var transactionDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    if (histPrices[`${cgtTransactions.tokenName}`]?.length !== 0) {
      for (const element of histPrices[cgtTransactions.tokenName]) {
        if (
          cgtTransactions.sentAt.split(`T`)[0] === element.date.split(`T`)[0]
        ) {
          price = element.price;
          break;
        }
      }

      if (price === 'price unavailable') {
        price = 0;
      }
    } else if (histPrices[`${cgtTransactions.tokenName}`]?.length === 0) {
      price = 0;
    }

    return {
      Date: transactionDate,
      'Transaction Type': cgtTransactions.transactionType,
      'Token Name': cgtTransactions.tokenName,
      hash: cgtTransactions.transactionHash,
      Amount: cgtTransactions.amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      Price: typeof price === 'number' ? price.toFixed(4) : price,
      Value:
        typeof price === 'number'
          ? (price * cgtTransactions.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '',
    };
  });

  const etlReport = items.map((cgtTransactions) => {
    let price = 'price unavailable';
    const dateArray = cgtTransactions.sentAt.split(`T`)[0].split(`-`);
    var transactionDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    if (histPrices[`${cgtTransactions.tokenName}`]?.length !== 0) {
      for (const element of histPrices[cgtTransactions.tokenName]) {
        if (
          cgtTransactions.sentAt.split(`T`)[0] === element.date.split(`T`)[0]
        ) {
          price = element.price;
          break;
        }
      }
      if (price === 'price unavailable') {
        price = 0;
      }
    } else if (histPrices[`${cgtTransactions.tokenName}`]?.length === 0) {
      price = 0;
    }

    return {
      Date: transactionDate,
      'Sent Amount':
        cgtTransactions.transactionType === 'disposal'
          ? cgtTransactions.amount
          : '',
      'Sent Currency':
        cgtTransactions.transactionType === 'disposal'
          ? cgtTransactions.tokenName
          : '',
      'Received Amount':
        cgtTransactions.transactionType === 'acquisition'
          ? cgtTransactions.amount
          : '',
      'Received Currency':
        cgtTransactions.transactionType === 'acquisition'
          ? cgtTransactions.tokenName
          : '',
      'Fee Amount':
        cgtTransactions.transactionType === 'disposal'
          ? cgtTransactions.feeAmount
          : 0,
      'Fee Currency':
        cgtTransactions.transactionType === 'disposal'
          ? cgtTransactions.feeCurrency
          : '',
      'Net Worth Amount':
        typeof price === 'number'
          ? (price * cgtTransactions.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '',
      'Net Worth Currency': 'USD',
      Label: cgtTransactions.transactionType,
      Description: '',
      'Token Name': cgtTransactions.tokenName,
      TxHash: cgtTransactions.transactionHash,
    };
  });

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (
    props.cgtTransactions[0] === undefined &&
    props.cgtTransactionsLoading === false
  ) {
    return (
      <Container>
        <Row>
          <Col
            md={12}
            style={{
              backgroundColor: 'white',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            <p>
              Choose the date range to query from the datepicker above to query
              the rewards database
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            md={2}
            style={{ backgroundColor: 'white', textAlign: 'center' }}
          ></Col>

          <Col md={12} style={{ backgroundColor: 'white', textAlign: 'left' }}>
            <div
              style={{
                display: 'table',
                height: '90px',
                maxWidth: '100%',
                whiteSpace: 'initial',
                overflow: 'hidden',
                margin: 'auto',
                backgroundColor: 'white',
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    );
  } else if (props.cgtTransactionsLoading === true) {
    return (
      <Row>
        <Col md={12} style={{ backgroundColor: 'white', textAlign: 'center' }}>
          <div
            style={{
              display: 'table',
              height: '280px',
              maxWidth: '300px',
              whiteSpace: 'initial',
              overflow: 'hidden',
              margin: 'auto',
              backgroundColor: 'white',
            }}
          >
            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
              <div
                style={{
                  textAlign: 'center',
                  wordWrap: 'breakWord',
                  whiteSpace: 'initial',
                }}
              >
                <p style={{ color: 'black', fontSize: '30px' }}>
                  Searching for records....
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  } else if (props.cgtTransactionsLoading === false) {
    return (
      <table style={{ width: '100%' }}>
        <thead style={{ padding: '2em' }}>
          <tr>
            <th style={{ textAlign: 'center' }}>
              <button
                type='button'
                onClick={() => requestSort('sentAt')}
                className={getClassNamesFor('sentAt')}
              >
                Date
              </button>
            </th>
            <th>
              <button
                type='button'
                onClick={() => requestSort('transactionType')}
                className={getClassNamesFor('transactionType')}
              >
                Transaction Type
              </button>
            </th>
            <th style={{ textAlign: 'center' }}>
              <button
                type='button'
                onClick={() => requestSort('tokenName')}
                className={getClassNamesFor('tokenName')}
              >
                Token Name
              </button>
            </th>
            <th>
              <button
                type='button'
                onClick={() => requestSort('amount')}
                className={getClassNamesFor('amount')}
              >
                Amount
              </button>
            </th>
            <th>
              <button
                type='button'
                onClick={() => requestSort('transactionPrice')}
                className={getClassNamesFor('transactionPrice')}
              >
                Price ({currencySymbol})
              </button>
            </th>

            <th>
              <button
                type='button'
                onClick={() => requestSort('transactionValue')}
                className={getClassNamesFor('transactionValue')}
              >
                Value ({currencySymbol})
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((cgtTransactions, index) => {
            let price = 'price unavailable';
            const dateArray = cgtTransactions.sentAt.split(`T`)[0].split(`-`);
            var transactionDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

            if (histPrices[`${cgtTransactions.tokenName}`]?.length !== 0) {
              for (const element of histPrices[cgtTransactions.tokenName]) {
                if (
                  cgtTransactions.sentAt.split(`T`)[0] ===
                  element.date.split(`T`)[0]
                ) {
                  price = element.price;
                  break;
                }
              }
              if (price === 'price unavailable') {
                price = 0;
              }
            } else if (
              histPrices[`${cgtTransactions.tokenName}`]?.length === 0
            ) {
              price = 0;
            }

            return (
              <tr style={{ height: '30px' }} key={index}>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {transactionDate}
                </td>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {cgtTransactions.transactionType}
                </td>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {cgtTransactions.tokenName}
                </td>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {cgtTransactions.amount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {typeof price === 'number' ? price.toFixed(4) : price}
                </td>
                <td style={{ wordBreak: 'break-all', textAlign: 'center' }}>
                  {typeof price === 'number'
                    ? (price * cgtTransactions.amount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''}
                </td>
                <td
                  style={{
                    minWidth: '45px',
                  }}
                ></td>
              </tr>
            );
          })}

          <tr>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}></td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}></td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}></td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}></td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}></td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    flex: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    gap: '10px',
                  }}
                >
                  <label for='raw' style={{ margin: 0 }}>
                    Raw
                  </label>
                  <input
                    type='radio'
                    id='Rraw'
                    style={{
                      margin: 0,
                    }}
                    value='raw'
                    checked={type === 'raw'}
                    onChange={() => setType('raw')}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    gap: '10px',
                  }}
                >
                  <label for='koinly' style={{ margin: 0 }}>
                    Koinly
                  </label>
                  <input
                    type='radio'
                    id='koinly'
                    style={{
                      margin: 0,
                    }}
                    value='koinly'
                    checked={type === 'koinly'}
                    onChange={() => setType('koinly')}
                  />
                </div>
              </div>
            </td>
            <td style={{ textAlign: 'center', borderBottom: 'none' }}>
              <div
                style={{ textAlign: 'center', borderBottom: 'none' }}
                className='tooltip'
              >
                <CSVLink
                  filename={
                    'RadixPortfolio_IncomeReport_' + Date.now() + '.csv'
                  }
                  data={type === 'raw' ? csvData : etlReport}
                >
                  <img style={{ width: '30px' }} src={csvDownload} alt='' />
                </CSVLink>
                <span className='tooltiptext'>Download Etl Report</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
};

export default CGTTable;
