import './style.css';

function Loading() {
  return (
    <div className="loading" style={{marginTop:"16rem"}}>
      <p>Checking alert conditions...</p>
    </div>
  );
}

export default Loading;
