import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Donate from './components/donate/donate';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Search from './components/search';
//import history from './components/history';
import createHistory from 'history/createBrowserHistory';

const history = createHistory();

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: 'https://api.ociswap.com/v1/graphql' }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

console.log(
  process.env.React_APP_GATEWAY_URL,
  'process.env.React_APP_GATEWAY_URL'
);

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <Route path='/' component={Search} exact />
            <Route path='/donate' component={Donate} exact />
          </Switch>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;
