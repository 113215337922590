import axios from 'axios';
import axiosRetry from 'axios-retry';

const gatewayApi = axios.create({
  baseURL: process.env.React_APP_GATEWAY_URL,
});

axiosRetry(gatewayApi, {
  // retries: 20,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    console.log('Retrying request', error.config.url);
    return error.response.status === 500;
  },
});

export default gatewayApi;
