import { ContainerClient } from '@azure/storage-blob';
import axios from 'axios';
import * as csvTojson from 'csvtojson';
import sha256 from 'crypto-js/sha256';
import { Parser } from 'json2csv';

function sumRewardsByDate(data) {
  const rewardSums = {};

  for (const item of data) {
    const rewardDate = item.rewardDate;
    const amount = parseFloat(item.reward);

    if (rewardSums[rewardDate]) {
      rewardSums[rewardDate].Amount += amount;
    } else {
      rewardSums[rewardDate] = {
        'Koinly Date': item.time,
        Amount: amount,
        Currency: 'XRD',
        Label: 'Staking Rewards',
        TxHash: sha256(`${amount}_${item.validator}_${item.time}`).toString(),
      };
    }
  }

  return Object.values(rewardSums);
}

export async function getContainerClient(address) {
  const res = await axios.post('/api/getContainerUrl', {
    address,
  });

  return res.data.containerURl;
}

export class Client {
  constructor(url) {
    this.client = new ContainerClient(url);
    this.files = [];
  }

  async listFiles() {
    try {
      let iter = this.client.listBlobsFlat();
      let blobItem = await iter.next();
      while (!blobItem.done) {
        this.files.push(blobItem);
        blobItem = await iter.next();
      }
    } catch (error) {
      console.log(error);
    }

    return this.files;
  }

  async getRawFile(name) {
    const downloadBlockBlobResponse = await this.client
      .getBlobClient(name)
      .download();
    const blob = await downloadBlockBlobResponse.blobBody;
    const href = URL.createObjectURL(blob);
    return href;
  }

  async getKoinlyFile(name) {
    const downloadBlockBlobResponse = await this.client
      .getBlobClient(name)
      .download();
    const blob = await downloadBlockBlobResponse.blobBody;
    return blob.text().then((text) => {
      return csvTojson()
        .fromString(text)
        .then((json) => {
          const fields = [
            'Koinly Date',
            'dailyReward',
            'Currency',
            'Label',
            'TxHash',
          ];
          const opts = { fields };
          const rewardSums = sumRewardsByDate(json);
          const json2csvParser = new Parser(opts);
          const csv = json2csvParser.parse(rewardSums);
          const blob = new Blob([csv], { type: 'text/csv' });
          const href = URL.createObjectURL(blob);
          return href;
        });
    });
  }
}
