import gatewayApi from '../gateway-api';
const queryDate = (epoch) => {
  return gatewayApi
    .post('/validator', {
      network_identifier: {
        network: 'mainnet',
      },
      validator_identifier: {
        address:
          'rv1qd0060jej9x0upkp6fdrednv8ct2yvdf2ydxm5a9t9ea63tp09c6u0ev6t8',
      },
      at_state_identifier: {
        epoch,
      },
    })
    .then(({ data }) => data.ledger_state.timestamp.split('T')[0]);
};

async function getStakes(address, epoch) {
  const result = await gatewayApi.post('/account/stakes', {
    network_identifier: {
      network: 'mainnet',
    },
    account_identifier: {
      address,
    },
    at_state_identifier: {
      epoch,
    },
  });
  return result.data;
}

async function transactions(address) {
  let nextCursor = '0';

  const stakeTransaction = [];
  do {
    const result = await gatewayApi.post('/account/transactions', {
      network_identifier: {
        network: 'mainnet',
      },
      account_identifier: {
        address,
      },
      cursor: nextCursor,
    });

    nextCursor = result.data.next_cursor;
    for (const transaction of result.data.transactions) {
      if (transaction.actions[0].type === 'StakeTokens') {
        stakeTransaction.push(transaction);
      }
    }
  } while (nextCursor);
  return stakeTransaction;
}

async function calculateRewards(address, start, end) {
  // console.log(end);
  let rewards = 0;
  let currentEpoch = start;
  const stakeTransactions = await transactions(address).catch((error) =>
    console.error(error, 'error at transaction api')
  );
  const data = [];
  let startStake;
  let endStake;

  while (currentEpoch <= end) {
    if (!startStake) {
      startStake = await getStakes(address, currentEpoch++).catch((error) =>
        console.error(error, 'error at stakes api')
      );
    } else {
      startStake = endStake;
    }

    endStake = await getStakes(address, currentEpoch++).catch((error) =>
      console.error(error, 'error at stakes api')
    );
    const rewardDate = new Date(startStake.ledger_state.timestamp)
      .toISOString()
      .split('T')[0];

    for (const stake of startStake.stakes) {
      let reward = 0;
      for (const stake_end of endStake.stakes) {
        if (
          stake.delegated_stake.token_identifier.rri === 'xrd_rr1qy5wfsfh' &&
          stake_end.delegated_stake.token_identifier.rri ===
            'xrd_rr1qy5wfsfh' &&
          stake_end.validator_identifier.address ===
            stake.validator_identifier.address
        ) {
          reward = (
            (stake_end.delegated_stake.value - stake.delegated_stake.value) /
            1000000000000000000
          ).toFixed(10);
          // console.log(reward, startStake.ledger_state.epoch);
        }
      }
      if (reward > 0) {
        for (const stakeTransaction of stakeTransactions) {
          if (
            stakeTransaction.actions[0].to_validator.address ===
            stake.validator_identifier.address
          ) {
            const startDate = new Date(startStake.ledger_state.timestamp);
            const endDate = new Date(endStake.ledger_state.timestamp);
            const transactionDate = new Date(
              stakeTransaction.transaction_status.confirmed_time
            );
            const isInBetween = Boolean(
              transactionDate >= startDate && transactionDate < endDate
            );

            if (isInBetween) {
              reward =
                reward -
                stakeTransaction.actions[0].amount.value / 1000000000000000000;
            }
          }
        }

        data.push({
          rewardDate,
          validator: stake.validator_identifier.address,
          tokenIdentifier: stake.delegated_stake.token_identifier.rri,
          epoch: startStake.ledger_state.epoch,
          time: startStake.ledger_state.timestamp,
          amount: stake.delegated_stake.value,
          reward,
        });
        rewards = rewards + reward;
      }
    }
  }
  console.log(data);

  return [rewards, data];
}

async function getStartEpoch(date) {
  if (new Date(date).getTime() <= new Date('2021-08-11').getTime()) return 3;
  let startEpoch = 1;
  let endEpoch = await gatewayApi
    .post('/validator', {
      network_identifier: {
        network: 'mainnet',
      },
      validator_identifier: {
        address:
          'rv1qd0060jej9x0upkp6fdrednv8ct2yvdf2ydxm5a9t9ea63tp09c6u0ev6t8',
      },
    })
    .then(({ data }) => data.ledger_state.epoch);

  while (startEpoch <= endEpoch) {
    const midEpoch = Math.floor((startEpoch + endEpoch) / 2);
    const midDate = await queryDate(midEpoch);

    if (new Date(date).getTime() > new Date(midDate).getTime()) {
      startEpoch = midEpoch + 1;
    } else if (new Date(date).getTime() < new Date(midDate).getTime()) {
      endEpoch = midEpoch - 1;
    } else if (new Date(date).getTime() === new Date(midDate).getTime()) {
      const prevDate = await queryDate(midEpoch - 1);
      if (new Date(prevDate).getTime() === new Date(midDate).getTime()) {
        endEpoch = midEpoch - 1;
      } else {
        return midEpoch;
      }
    }
  }
}

async function getEndEpoch(date) {
  if (new Date(date).getTime() < new Date('2021-08-11').getTime()) return 3;

  let startEpoch = 1;
  let endEpoch = await gatewayApi
    .post('/validator', {
      network_identifier: {
        network: 'mainnet',
      },
      validator_identifier: {
        address:
          'rv1qd0060jej9x0upkp6fdrednv8ct2yvdf2ydxm5a9t9ea63tp09c6u0ev6t8',
      },
    })
    .then(({ data }) => data.ledger_state.epoch);

  if (
    new Date(new Date().toISOString().split('T')[0]).getTime() ===
    new Date(date).getTime()
  )
    return endEpoch;

  while (startEpoch <= endEpoch) {
    const midEpoch = Math.floor((startEpoch + endEpoch) / 2);
    const midDate = await queryDate(midEpoch);

    if (new Date(date).getTime() > new Date(midDate).getTime()) {
      startEpoch = midEpoch + 1;
    } else if (new Date(date).getTime() < new Date(midDate).getTime()) {
      endEpoch = midEpoch - 1;
    } else if (new Date(date).getTime() === new Date(midDate).getTime()) {
      const nextDate = await queryDate(midEpoch + 1);
      if (new Date(nextDate).getTime() === new Date(midDate).getTime()) {
        startEpoch = midEpoch + 1;
      } else {
        return midEpoch;
      }
    }
  }
}

export default async function start(address, startDate, endDate) {
  const [, data] = await calculateRewards(
    address,
    await getStartEpoch(startDate),
    await getEndEpoch(endDate)
  );

  return data;
}
