import React from 'react';
import { Line, Circle } from 'rc-progress';
import './sortableTable.css';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const UnstakeTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.unstakeValidators);
  console.info(items);
  const epochDuration=props.epochDuration;
  console.log(epochDuration);
  //epoch length in seconds
  let epochLength = (86400/2101);
  console.log(epochLength);

  if(epochDuration!=0){
    //length of epoch in minutes
    epochLength = (86400/epochDuration);
    console.log(epochLength);
  }

 // console.log("epochDuration "+epochDuration);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

if (props.unstakeValidators[0] == undefined){
  console.log("no unstake validators found");
  return(
  
    <table style={{width:'100%'}}> 
     <thead style={{padding:'0em'}}>
     <tr></tr>
     <tr></tr>
     
    <tr style={{height:'10px', verticalAlign: 'center' ,textAlign: 'center',  alignContent: 'center', fontWeight: 'bold', color:'black'}}>There are no unstake transactions in progress </tr>
    <tr></tr>
    <tr></tr>
    </thead> 
    </table>  

    
  )
}
else{
  console.log("UNSTAKE validators found");

return (
    <table style={{width:'100%'}}> 
      <thead style={{padding:'0em'}}>
        <tr>
          <th style={{textAlign:'center'}}>
            <button
              type="button" 
              onClick={() => requestSort('validatorName')}
              className={getClassNamesFor('validatorName')}
            >
            Validator Name
            </button>
          </th>
          <th style={{textAlign:'center'}}>
            <button
              type="button" 
              onClick={() => requestSort('amount')}
              className={getClassNamesFor('amount')}
            >
               Amount
            </button>
          </th>
          <th>
            <button
              type="button" 
              onClick={() => requestSort('epochsUntil')}
              className={getClassNamesFor('epochsUntil')}
            >
            Epochs Until
            </button>
          </th>
          <th>
            <button
              type="button" 
              onClick={() => requestSort('timeUntil')}
              className={getClassNamesFor('timeUntil')}
            >
            Time Until
            </button>
            </th>
            <th>
            <button
            >
            Progress
            </button>
            </th>

        </tr>
      </thead>
      <tbody>
      
          
        {items.map((unstakeValidators,index) => {

        var urlRoot = "https:\/\/explorer.radixdlt.com\/#\/validators\/";
        
        return (
     

         <tr style={{height:'30px'}} key={index}>
         <td style={{textAlign: 'center'}}><a href ={urlRoot+unstakeValidators.validatorName} target="_blank">{unstakeValidators.validatorName.substring(0,40)}</a></td>
         <td style={{textAlign: 'center'}}>{unstakeValidators.amount}</td>
         <td style={{textAlign: 'center'}}>{unstakeValidators.epochsUntil}</td>
         <td style={{textAlign: 'center'}}>{(unstakeValidators.epochsUntil/epochLength).toFixed(2)} Days</td>
       
        <td style={{textAlign: 'center'}}>
        <Line percent={(500-unstakeValidators.epochsUntil)/500 *100}  trailWidth= "1" strokeLinecap="round" strokeWidth="7" trailColor="lightgrey" strokeColor="Maroon" />
         </td>  
         </tr>
        )})}
        
       

      </tbody>
    </table>
  );
};
}


export default UnstakeTable;