//import {Telegram } from "@material-ui/icons"; 
//import { height, sizeHeight } from "@material-ui/system";
import React from "react";
import "./footer.css";
import radixstakerlogo from "../redCapeTransparentRed.png";
import donate from "../donate.jpg";
//import telegramlogo from "../telegram.png";
import { Link } from 'react-router-dom'
import Donate from "../components/donate/donate";
  



function Footer() {
  return (
    <div className="footer">
     This Radix Community tool is brought to you by:
        <div className="footer_logo">
        <a href="https://radixstaker.com"  target="_blank" rel="noreferrer">
          <img src={radixstakerlogo} alt="" />
        </a>
    </div>
    
    <div className="footer_stake">Stake the Vampire!</div>

     <div className="footer_telegram">
     <a href="https://t.me/radixStakerDelegator" target="_blank" rel="noreferrer">
       Join our Telegram Group 
       </a>  
      </div>
      <div className="footer_logo">

      <Link to={"./donate"} target="_blank"><img width="100px" src={donate} alt=""/></Link>
      </div>
  </div>
  );
}

export default Footer;
